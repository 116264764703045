// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agegate-js": () => import("./../../../src/pages/agegate.js" /* webpackChunkName: "component---src-pages-agegate-js" */),
  "component---src-pages-contenidos-js": () => import("./../../../src/pages/contenidos.js" /* webpackChunkName: "component---src-pages-contenidos-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-experiencias-js": () => import("./../../../src/pages/experiencias.js" /* webpackChunkName: "component---src-pages-experiencias-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sobre-stella-js": () => import("./../../../src/pages/sobre-stella.js" /* webpackChunkName: "component---src-pages-sobre-stella-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-campaigns-js": () => import("./../../../src/templates/campaigns.js" /* webpackChunkName: "component---src-templates-campaigns-js" */),
  "component---src-templates-experiences-js": () => import("./../../../src/templates/experiences.js" /* webpackChunkName: "component---src-templates-experiences-js" */)
}

